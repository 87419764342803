import React from "react";
import { Grid } from "@material-ui/core";

const PageHeader = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <img
            src="https://obbcbe.jitooi.com/image/xDXuEHAKRkkJUatXcXrhqFqq6SfB5j0H4BdaZOtU.png"
            style={{ width: "100%", display: "inline-block" }}
            alt="img"
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={3}>
        <Grid item sm={12}>
          <h2>New building search</h2>
        </Grid>
      </Grid> */}
    </>
  );
};

export default PageHeader;
