import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const IntOrExtLink = ({ item }) => {
  if (item.slug) {
    return (
      <Card style={{ width: "100%" }}>
        <Link to={"/page/" + item.slug} style={{ textDecoration: "none" }}>
          <CardActionArea className="fourGrids">
            <CardMedia
              style={{ height: 140, float: "top" }}
              image={item.src}
              title={item.title}
            />
            <CardContent style={{ minHeight: 140 }}>
              <div
                className="fourgrids-title1"
                style={{ fontSize: `${item.font_size}px` }}
              >
                {item.title}
              </div>
              <div
                className="fourgrids-title2"
                style={{ fontSize: `${item.font_size2}px` }}
              >
                {item.caption}
              </div>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    );
  } else {
    return (
      <Card style={{ width: "100%" }}>
        <a href={item.url} style={{ textDecoration: "none" }}>
          <CardActionArea className="fourGrids">
            <CardMedia
              style={{ height: 140, float: "top" }}
              image={item.src}
              title={item.title}
            />
            <CardContent style={{ minHeight: 140 }}>
              <div
                className="fourgrids-title1"
                style={{ fontSize: `${item.font_size}px` }}
              >
                {item.title}
              </div>
              <div
                className="fourgrids-title2"
                style={{ fontSize: `${item.font_size2}px` }}
              >
                {item.caption}
              </div>
            </CardContent>{" "}
          </CardActionArea>
        </a>
      </Card>
    );
  }
};

const FourGrids = () => {
  const [cardsContent, setCardsContent] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/getHomepageComponents")
      .then((res) => {
        setCardsContent(res.data.cards);
      });
  }, []);

  return (
    <Grid container spacing={1}>
      {cardsContent.map((item) => {
        return (
          <Grid item sm={3} key={item.id} style={{ width: "100%" }}>
            <IntOrExtLink item={item} key={item.id} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FourGrids;
