import React, { useState, useEffect } from "react";
import {
  AppBar,
  Container,
  Hidden,
  makeStyles,
  Toolbar,
  Fab,
} from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";
import axios from "axios";
import HideOnScroll from "./HideOnScroll";
import SideDrawer from "./SideDrawer";
import BackToTop from "./BackToTop";
import HeaderImage from "./HeaderImage";
import DesktopNavbar from "./DesktopNavbar";

const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  navListDisplayFlex: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  linkText: {
    display: "flex",
    textDecoration: "none",
    textTransform: "uppercase",
    color: "white",
  },
});

const Header = () => {
  const classes = useStyles();

  const [navLinks, setNavlinks] = useState([]);
  const [headerImg, setHeaderImg] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/headerMenu").then((res) => {
      setNavlinks(res.data.menu);
      setHeaderImg(res.data.headerImage);
    });
  }, []);

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed">
          <Hidden smDown>
            <HeaderImage items={headerImg} />
          </Hidden>
          <Toolbar component="nav">
            <Container maxWidth="lg" className={classes.navbarDisplayFlex}>
              <Hidden smDown>
                <DesktopNavbar navLinks={navLinks} />
              </Hidden>
              <Hidden mdUp>
                <SideDrawer navLinks={navLinks} headerImg={headerImg} />
              </Hidden>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />

      <BackToTop>
        <Fab size="large" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </BackToTop>
    </>
  );
};

export default Header;
