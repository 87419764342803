import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `black`,
  },
});

const SideDrawer = ({ navLinks, headerImg }) => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });
  const [openCollapse, setOpenCollapse] = useState({ settings: false });
  const [headerImage, setHeaderImage] = useState("");

  useEffect(() => {
    let subs = {};
    navLinks.map((main) => {
      if (main.sub.length) {
        const mainid = `main${main.id}`;
        subs[mainid] = false;
      }
      return false;
    });
    setOpenCollapse({ ...openCollapse, ...subs });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navLinks]);

  useEffect(() => {
    if (headerImg[0]) {
      setHeaderImage(headerImg[0].src);
    }
  }, [headerImg]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const IntOrExtLink = ({ item }) => {
    if (item.slug === null && item.url === null) {
      return (
        <ListItem button>
          <ListItemText
            primary={item.title}
            onClick={() => {
              const mainid = `main${item.id}`;
              setOpenCollapse({
                ...openCollapse,
                [mainid]: !openCollapse[mainid],
              });
            }}
          />
          {item.sub && item.sub.length ? (
            openCollapse[`main${item.id}`] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : (
            ""
          )}
        </ListItem>
      );
    } else if (item.slug) {
      return (
        <Link
          to={"/" + item.slug}
          className={classes.linkText}
          onClick={toggleDrawer("right", false)}
        >
          <ListItem button>
            <ListItemText primary={item.title} />
          </ListItem>
        </Link>
      );
    } else if (item.url) {
      return (
        <a href={item.url} className={classes.linkText}>
          {item.title}
        </a>
      );
    }
  };

  const Submenu = ({ sub }) => {
    if (sub.length === 0) {
      return <></>;
    } else {
      return (
        <Collapse
          in={openCollapse[`main${sub[0]["parentId"]}`]}
          timeout="auto"
          unmountOnExit
          style={{ marginLeft: "10px" }}
        >
          {sub.map((sub) => {
            return <IntOrExtLink item={sub} key={sub.title} />;
          })}
        </Collapse>
      );
    }
  };

  const sideDrawerList = (anchor) => (
    <div className={classes.list}>
      <List component="nav">
        <Link
          to="/"
          className={classes.linkText}
          onClick={toggleDrawer("right", false)}
        >
          <ListItem button>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        {navLinks.map((main) => (
          <React.Fragment key={main.title}>
            <IntOrExtLink item={main} />
            <Submenu sub={main.sub} />
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <Menu fontSize="large" />
      </IconButton>
      <div
        style={{
          width: "100%",
          backgroundImage: "url(" + headerImage + ")",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        {sideDrawerList("right")}
      </Drawer>
    </React.Fragment>
  );
};

export default SideDrawer;
