import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "../style/bootstrap.css";

const ArticlePageContent = () => {
  let { slug } = useParams();

  const [page, setPage] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/getPage/" + slug)
      .then((res) => {
        setPage(res.data);
        document.title = res.data.title;
      });
  }, [slug]);

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div className="article-page-content-wrapper">
        <div dangerouslySetInnerHTML={{ __html: page.content }} />

        {page.category_id !== 1 ? (
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item sm={5}>
              {page.previous ? (
                <Link
                  to={`/pages/${page.categorySlug}/${page.previous.slug}`}
                  className="articleNavArrows"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div style={{ marginTop: "5px" }}>
                    <ArrowBackIosIcon />
                  </div>
                  <div>{page.previous.title}</div>
                </Link>
              ) : (
                ""
              )}
            </Grid>
            <Grid item sm={2} />
            <Grid item sm={5}>
              {page.next ? (
                <Link
                  to={`/pages/${page.categorySlug}/${page.next.slug}`}
                  className="articleNavArrows"
                  style={{ justifyContent: "flex-end" }}
                >
                  <div>{page.next.title}</div>
                  <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <ArrowForwardIosIcon />
                  </div>
                </Link>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ArticlePageContent;
