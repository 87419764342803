import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  const [page, setPage] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/getPage/lien-he")
      .then((res) => {
        setPage(res.data);
        document.title = res.data.title;
      });
  }, []);

  return (
    <div className="article-page-content-wrapper">
      <Grid container spacing={6} style={{ marginTop: "20px" }}>
        <Grid item md={12}>
          <h2>{page.description}</h2>
          <p>{page.content}</p>
        </Grid>

        <Grid item md={6}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              required
              label="Họ và tên"
              name="name"
              variant="outlined"
            />
            <TextField required label="Email" name="email" variant="outlined" />
            <TextField
              required
              label="Số điện thoại"
              name="phone"
              variant="outlined"
            />
            <TextField label="Chủ đề" name="subject" variant="outlined" />
            <TextField
              label="Nội dung"
              multiline
              rows={5}
              name="message"
              variant="outlined"
            />
            <Button
              variant="contained"
              style={{ backgroundColor: "#e67e22", color: "#fff" }}
            >
              Gửi
            </Button>
          </form>
        </Grid>
        <Grid item md={6}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.3152390383325!2d105.78937111525666!3d21.020068893450016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab50d9500a4f%3A0x3490fb756ccc73a6!2zMjE5IFAuIFRydW5nIEtpzIFuaCwgWcOqbiBIb8OgLCBD4bqndSBHaeG6pXksIEjDoCBO4buZaSwgVmlldG5hbQ!5e0!3m2!1sen!2ssg!4v1618073759507!5m2!1sen!2ssg"
            width="100%"
            height="400"
            border="0"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactPage;
