import React from "react";
import { Home } from "@material-ui/icons";
import { Link } from "react-router-dom";

const IntOrExtLink = ({ item }) => {
  if (item.slug === null && item.url === null) {
    return <div className="nav__menu-link">{item.title}</div>;
  } else if (item.slug) {
    return (
      <Link to={"/" + item.slug} className="nav__menu-link">
        {item.title}
      </Link>
    );
  } else if (item.url) {
    return (
      <a href={item.url} className="nav__menu-link">
        {item.title}
      </a>
    );
  }
};

const Submenu = ({ sub }) => {
  if (sub.length === 0) {
    return <></>;
  } else {
    return (
      <ul className="nav__submenu">
        {sub.map((sub) => {
          return (
            <li className="nav__submenu-item" key={sub.id}>
              <IntOrExtLink item={sub} />
            </li>
          );
        })}
      </ul>
    );
  }
};

const DesktopNavbar = ({ navLinks }) => {
  return (
    <nav className="nav">
      <ul className="nav__menu">
        <li
          className="nav__menu-item"
          style={{ height: "38px", marginTop: "5px" }}
        >
          <Link to="/" className="nav__menu-link">
            <Home fontSize="large" />
          </Link>
        </li>
        {navLinks.map((main) => {
          return (
            <li className="nav__menu-item" key={main.id}>
              <IntOrExtLink item={main} />
              <Submenu sub={main.sub} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default DesktopNavbar;
