import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const CenterBanner = () => {
  const [banner, setBanner] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/getHomepageComponents")
      .then((res) => {
        setBanner(res.data.banner);
      });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "150px",
        marginBottom: "10px",
        backgroundImage: `url(${banner.src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {banner.slug ? (
        <Link to={"/page/" + banner.slug} className="bannerLinkContainer">
          <span style={{ fontSize: `${banner.font_size}px` }}>
            {banner.title}
          </span>
          <span style={{ fontSize: `${banner.font_size2}px` }}>
            {banner.caption}
          </span>
        </Link>
      ) : (
        <a href={banner.url} className="bannerLinkContainer">
          <span style={{ fontSize: `${banner.font_size}px` }}>
            {banner.title}
          </span>
          <span style={{ fontSize: `${banner.font_size2}px` }}>
            {banner.caption}
          </span>
        </a>
      )}
    </div>
  );
};

export default CenterBanner;
