import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Grid, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import previewImg from "../data/preview.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    padding: theme.spacing(1.2),
  },
  avatar: { marginRight: theme.spacing(5) },
  paginator: {
    justifyContent: "center",
    padding: "10px",
  },
}));

const CategoryPageContent = () => {
  const classes = useStyles();
  let { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [pagesSummary, setPagesSummary] = useState([]);

  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/getCategoryIndex/" + slug)
      .then((res) => {
        setPagesSummary(res.data.pagesSummary);
        setNoOfPages(Math.ceil(pagesSummary.length / itemsPerPage));

        document.title = res.data.category.title;
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    setNoOfPages(Math.ceil(pagesSummary.length / itemsPerPage));
  }, [pagesSummary]);

  return (
    <div className="article-page-content-wrapper">
      {loading ? "loading" : ""}

      {pagesSummary.length ? (
        pagesSummary
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((item) => (
            <Grid
              container
              spacing={3}
              key={item.slug}
              style={{ border: "1px solid #eee", padding: "5px" }}
            >
              <Grid item sm={3}>
                <img
                  src={item.image_url ? item.image_url : previewImg}
                  alt={item.title}
                  style={{
                    height: "150px",
                    width: "300px",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid item sm={9}>
                <Link
                  to={"/pages/" + item.groupSlug + "/" + item.slug}
                  className="titleLink"
                >
                  {item.title}
                </Link>

                <p style={{ color: "#525455" }}>{item.description}</p>
                <Link
                  to={"/pages/" + item.groupSlug + "/" + item.slug}
                  style={{
                    textDecoration: "none",
                    color: "#e67e22",
                  }}
                >
                  Tìm hiểu thêm
                </Link>
              </Grid>
            </Grid>
          ))
      ) : (
        <div>No Pages</div>
      )}

      <Grid container spacing={3}>
        <Grid item sm={2} />
        <Grid item sm={10} xs={12}>
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            classes={{ ul: classes.paginator }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryPageContent;
