import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import { ProjectPreviewContext } from "../../context/ProjectPreviewContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
    fontSize: "50px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProjectFilter = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState([]);
  const [advanced, setAdvanced] = useState("Show");
  const [projectCategoryTitle, setProjectCategoryTitle] = useState("");
  const { setProjectList } = useContext(ProjectPreviewContext);

  let { projectCategorySlug } = useParams();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/getFilters").then((res) => {
      setFilters(res.data);
    });

    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/getProjectCategoryTitle/" +
          projectCategorySlug
      )
      .then((res) => {
        setProjectCategoryTitle(res.data);
      });
  }, [projectCategorySlug]);

  const handleChange = (event) => {
    const nameType = event.target.name.split("|||");
    const newArr = selected.filter((v) => v.name !== nameType[0]);
    setSelected([
      ...newArr,
      {
        name: nameType[0],
        value: event.target.value,
        type: nameType[1],
      },
    ]);
    if (nameType[1] === "number" && nameType[0].slice(-5) !== "-sign") {
      const signExist = selected.filter(
        (v) => v.name === nameType[0] + "-sign"
      );
      if (!signExist.length) {
        setSelected([
          ...newArr,
          {
            name: nameType[0] + "-sign",
            value: "equal",
            type: nameType[1],
          },
        ]);
      }
    }
  };

  const showAdvancedClick = () => {
    if (advanced === "Show") {
      setAdvanced("Hide");
    } else {
      setAdvanced("Show");
    }
  };

  const searchClick = () => {
    const clearEmptySelections = selected.filter((v) => v.value !== "");
    if (clearEmptySelections.length) {
      console.log(clearEmptySelections);
      axios
        .post(process.env.REACT_APP_API_URL + "/getFilterResults", {
          category: projectCategorySlug,
          selected: clearEmptySelections,
        })
        .then((res) => {
          setProjectList(res.data);
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/getAllProjectsPreview/" +
            projectCategorySlug
        )
        .then((res) => {
          setProjectList(res.data);
        });
    }
  };

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <h3>{projectCategoryTitle}</h3>
      </div>
      <div>
        {filters.basic &&
          filters.basic.map((item) => {
            if (item.filter_type === "selection") {
              return (
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  key={item.id}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {item.label}
                  </InputLabel>
                  <Select
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label).length
                        ? selected.filter((v) => v.name === item.label)[0].value
                        : ""
                    }
                    name={item.label + "|||" + item.filter_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {item.values.map((val, index) => {
                      return (
                        <MenuItem key={index} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            } else if (item.filter_type === "text") {
              return (
                <FormControl className={classes.formControl} key={item.id}>
                  <TextField
                    label={item.label}
                    variant="filled"
                    name={item.label + "|||" + item.filter_type}
                    onChange={handleChange}
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label).length
                        ? selected.filter((v) => v.name === item.label)[0].value
                        : ""
                    }
                  />
                </FormControl>
              );
            } else if (item.filter_type === "number") {
              return (
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    style={{ width: "20%" }}
                    onChange={handleChange}
                    name={item.label + "-sign|||" + item.filter_type}
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label + "-sign")
                        .length
                        ? selected.filter(
                            (v) => v.name === item.label + "-sign"
                          )[0].value
                        : "equal"
                    }
                  >
                    <MenuItem value="equal">=</MenuItem>
                    <MenuItem value="more">{">"}</MenuItem>
                    <MenuItem value="less">{"<"}</MenuItem>
                  </Select>
                  <TextField
                    label={item.label}
                    variant="filled"
                    style={{ width: "70%" }}
                    type="number"
                    name={item.label + "|||" + item.filter_type}
                    onChange={handleChange}
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label).length
                        ? selected.filter((v) => v.name === item.label)[0].value
                        : ""
                    }
                  />
                </FormControl>
              );
            }
            return null;
          })}
      </div>
      <div style={{ width: "100%" }}>
        <Button style={{ fontSize: "15px" }} onClick={showAdvancedClick}>
          {advanced === "Show" ? "Tìm kiếm nâng cao" : "Ẩn Tìm Kiếm Nâng Cao"}
        </Button>
      </div>

      <div
        style={advanced === "Hide" ? { display: "block" } : { display: "none" }}
      >
        {filters.advanced &&
          filters.advanced.map((item) => {
            if (item.filter_type === "selection") {
              return (
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  key={item.id}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {item.label}
                  </InputLabel>
                  <Select
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label).length
                        ? selected.filter((v) => v.name === item.label)[0].value
                        : ""
                    }
                    name={item.label + "|||" + item.filter_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {item.values.map((val, index) => {
                      return (
                        <MenuItem key={index} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            } else if (item.filter_type === "text") {
              return (
                <FormControl className={classes.formControl} key={item.id}>
                  <TextField
                    label={item.label}
                    variant="filled"
                    name={item.label + "|||" + item.filter_type}
                    onChange={handleChange}
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label).length
                        ? selected.filter((v) => v.name === item.label)[0].value
                        : ""
                    }
                  />
                </FormControl>
              );
            } else if (item.filter_type === "number") {
              return (
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    style={{ width: "20%" }}
                    onChange={handleChange}
                    name={item.label + "-sign|||" + item.filter_type}
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label + "-sign")
                        .length
                        ? selected.filter(
                            (v) => v.name === item.label + "-sign"
                          )[0].value
                        : "equal"
                    }
                  >
                    <MenuItem value="equal">=</MenuItem>
                    <MenuItem value="more">{">"}</MenuItem>
                    <MenuItem value="less">{"<"}</MenuItem>
                  </Select>
                  <TextField
                    label={item.label}
                    variant="filled"
                    style={{ width: "70%" }}
                    type="number"
                    name={item.label + "|||" + item.filter_type}
                    onChange={handleChange}
                    value={
                      selected.length &&
                      selected.filter((v) => v.name === item.label).length
                        ? selected.filter((v) => v.name === item.label)[0].value
                        : ""
                    }
                  />
                </FormControl>
              );
            }
            return null;
          })}
      </div>
      <div style={{ marginLeft: "8px", marginTop: "10px" }}>
        <Button variant="contained" onClick={searchClick}>
          Tìm kiếm
        </Button>
      </div>
    </>
  );
};

export default ProjectFilter;
