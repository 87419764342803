import React, { useState, useEffect, useContext } from "react";
import { Grid, makeStyles, List } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import { ProjectPreviewContext } from "../../context/ProjectPreviewContext";
import SingleResult from "./SingleResult";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    padding: theme.spacing(1.2),
  },
  avatar: { marginRight: theme.spacing(5) },
  paginator: {
    justifyContent: "center",
    padding: "10px",
  },
}));

const AllProjects = () => {
  const classes = useStyles();
  const itemsPerPage = 5;
  const { projectList, setProjectList } = useContext(ProjectPreviewContext);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  let { projectCategorySlug } = useParams();

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/getAllProjectsPreview/" +
          projectCategorySlug
      )
      .then((res) => {
        setProjectList(res.data.projectList);
        setNoOfPages(Math.ceil(projectList.length / itemsPerPage));
        document.title = res.data.projectCategoryName;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCategorySlug]);

  useEffect(() => {
    setNoOfPages(Math.ceil(projectList.length / itemsPerPage));
  }, [projectList]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          sm={2}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>{projectList.length ? projectList.length : 0} kết quả</div>
        </Grid>
        <Grid item sm={10} xs={12}>
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            classes={{ ul: classes.paginator }}
          />
        </Grid>
      </Grid>

      <List dense compoent="span">
        {!projectList.length
          ? "Không có dự án"
          : projectList
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((projectItem) => {
                return (
                  <SingleResult
                    item={projectItem}
                    key={projectItem.id}
                    projectCategorySlug={projectCategorySlug}
                  />
                );
              })}
      </List>

      <Grid container spacing={3}>
        <Grid item sm={2} />
        <Grid item sm={10} xs={12}>
          <Pagination
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            classes={{ ul: classes.paginator }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AllProjects;
