import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { ProjectPreviewContext } from "./context/ProjectPreviewContext";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import HomepageContent from "./components/HomepageContent";
import ArticlePageContent from "./components/ArticlePageContent";
import CategoryPageContent from "./components/CategoryPageContent";
import ProjectList from "./components/ProjectPage";
import SingleProjectContent from "./components/SingleProjectContent";
import ContactPage from "./pages/ContactPage";
import NotFound from "./pages/NotFound";

function App() {
  const [projectList, setProjectList] = useState([]);

  return (
    <>
      <Container maxWidth="lg" style={{ padding: "0px" }}>
        <Header />
        <ProjectPreviewContext.Provider value={{ projectList, setProjectList }}>
          <Switch>
            <Route exact path="/" component={HomepageContent} />
            <Route exact path="/page/lien-he" component={ContactPage} />
            <Route exact path="/page/:slug" component={ArticlePageContent} />
            <Route
              exact
              path="/pages/:pageGroup/:slug"
              component={ArticlePageContent}
            />
            <Route
              exact
              path="/category/:slug"
              component={CategoryPageContent}
            />
            <Route
              exact
              path="/project/:projectCategorySlug"
              component={ProjectList}
            />
            <Route
              exact
              path="/project/:projectCategorySlug/:projectSlug"
              component={SingleProjectContent}
            />
            <Route path="/*" component={NotFound} />
          </Switch>
        </ProjectPreviewContext.Provider>
        <Footer />
      </Container>
    </>
  );
}

export default App;
