import React from "react";
import { Grid } from "@material-ui/core";

// import { useParams } from "react-router-dom";

import PageHeader from "./PageHeader";
import ProjectFilter from "./ProjectFilter";
import AllProjects from "./AllProjects";

const ProjectList = () => {
  // let { slug } = useParams();

  return (
    <div className="article-page-content-wrapper">
      <PageHeader />
      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <ProjectFilter />
        </Grid>
        <Grid item sm={9} xs={12}>
          <AllProjects />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectList;
