import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const imgStyle = {
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
};
const linkStyle = {
  width: "100%",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "5px",
};

const HeaderImage = ({ items }) => {
  const gridSize = items.length === 0 ? true : parseInt(12 / items.length);

  return (
    <Container maxWidth="lg" style={{ padding: "0px" }}>
      <Grid container justify="center">
        {items.map((item) => {
          if (item.url === null && item.slug === null) {
            return (
              <Grid item sm={gridSize} key={item.id}>
                <div style={linkStyle}>
                  <img src={item.src} alt={item.name} style={imgStyle} />
                </div>
              </Grid>
            );
          } else if (item.slug) {
            return (
              <Grid item sm={gridSize} key={item.id}>
                <Link to={"/page/" + item.slug} style={linkStyle}>
                  <img src={item.src} alt={item.name} style={imgStyle} />
                </Link>
              </Grid>
            );
          } else if (item.url) {
            return (
              <Grid item sm={gridSize} key={item.id}>
                <a href={item.url} style={linkStyle}>
                  <img src={item.src} alt={item.name} style={imgStyle} />
                </a>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </Container>
  );
};

export default HeaderImage;
