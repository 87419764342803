import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import previewImg from "../../data/preview.png";

const SingleResult = ({ item, projectCategorySlug }) => {
  return (
    <Grid
      container
      spacing={3}
      key={item.id}
      style={{ border: "1px solid #eee", padding: "5px" }}
    >
      <Grid item sm={3}>
        <img
          src={item.image ? item.image : previewImg}
          alt={item.title}
          style={{
            height: "130px",
            width: "300px",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item sm={9}>
        <Link
          to={"/project/" + projectCategorySlug + "/" + item.slug}
          className="titleLink"
        >
          {item.title}
        </Link>

        <Grid container>
          {item.previewSpecs.map((stat, index) => (
            <Grid item sm={6} xs={12} key={index}>
              <Grid container>
                <Grid item xs={5} style={{ color: "#888" }}>
                  {stat.label}
                </Grid>
                <Grid item xs={7}>
                  {stat.value}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleResult;
