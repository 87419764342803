import React, { useEffect } from "react";
import Carousel from "./HomePage/Carousel2";
import CenterBanner from "./HomePage/CenterBanner";
import FourGrids from "./HomePage/FourGrids";

const HomepageContent = () => {
  useEffect(() => {
    document.title = "GEEB";
  }, []);

  return (
    <div className="article-page-content-wrapper">
      <Carousel />
      <CenterBanner />
      <FourGrids />
    </div>
  );
};

export default HomepageContent;
