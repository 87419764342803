import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";

import "../../style/SecondExample.scss";

function Project(props) {
  return (
    <Paper
      className="Project"
      style={
        props.item.src
          ? {
              backgroundImage: `url(${props.item.src})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
          : {
              backgroundColor: props.item.color,
            }
      }
      elevation={10}
    >
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          marginRight: "10px",
          marginBottom: "10px",
          backgroundColor: "rgba(0,0,0,0.5)",
          padding: "10px",
        }}
      >
        <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
          {props.item.title}
        </div>
        <div>{props.item.caption}</div>
        {/* <Button className="CheckButton">Check it out!</Button> */}
      </div>
    </Paper>
  );
}

const MyProjectsExample = () => {
  const [sliderState] = useState({
    autoPlay: true,
    timer: 10000,
    animation: "fade",
    indicators: true,
    timeout: {
      appear: 800,
      enter: 0,
      exit: 0,
    },
    navButtonsAlwaysVisible: false,
    navButtonsAlwaysInvisible: false,
  });

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/getSlides").then((res) => {
      setSlides(res.data);
    });
  }, []);

  return (
    <div style={{ color: "#494949" }}>
      <Carousel
        className="SecondExample"
        autoPlay={sliderState.autoPlay}
        timer={sliderState.timer}
        animation={sliderState.animation}
        indicators={sliderState.indicators}
        timeout={sliderState.timeout}
        navButtonsAlwaysVisible={sliderState.navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={sliderState.navButtonsAlwaysInvisible}
      >
        {slides.map((slide) => {
          return <Project item={slide} key={slide.id} />;
        })}
      </Carousel>
    </div>
  );
};

export default MyProjectsExample;
