import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { Paper, Tabs, Tab, Box } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";

import "../style/ThirdExample.scss";

const sliderState = {
  autoPlay: true,
  timer: 50000,
  animation: "fade",
  indicators: true,
  timeout: 1000,
  navButtonsAlwaysVisible: false,
  navButtonsAlwaysInvisible: false,
};

function Project(props) {
  return (
    <Paper
      className="Project"
      style={
        props.item
          ? {
              backgroundImage: `url(${props.item})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
          : {
              backgroundColor: props.item.color,
            }
      }
      elevation={10}
    ></Paper>
  );
}

const SingleProjectContent = () => {
  const [value, setValue] = useState(0);
  const [projectContent, setProjectContent] = useState({
    specs: [],
    contents: [],
    images: [],
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { projectSlug } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/getProject/" + projectSlug)
      .then((res) => {
        setProjectContent(res.data);
        document.title = res.data.title;
      });
  }, [projectSlug]);

  return (
    <div className="article-page-content-wrapper">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <img
            src={projectContent.top_image}
            style={{ width: "100%", display: "inline-block" }}
            alt="img"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <h2>{projectContent.title}</h2>
        </Grid>
      </Grid>
      {/* mid section */}
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <div>
            <Carousel
              className="ThirdExample"
              autoPlay={sliderState.autoPlay}
              timer={sliderState.timer}
              animation={sliderState.animation}
              indicators={sliderState.indicators}
              timeout={sliderState.timeout}
              navButtonsAlwaysVisible={sliderState.navButtonsAlwaysVisible}
              navButtonsAlwaysInvisible={sliderState.navButtonsAlwaysInvisible}
            >
              {projectContent.images &&
                projectContent.images.map((slide) => (
                  <Project item={slide.image} key={slide.id} />
                ))}
            </Carousel>
          </div>
        </Grid>
        {/* info section */}
        <Grid item sm={8}>
          {projectContent.specs &&
            projectContent.specs.map((item) => (
              <Grid
                container
                style={{
                  borderBottom: "1px solid #ccc",
                  backgroundColor: "#F8F8F8",
                  padding: "5px",
                }}
                key={item.id}
              >
                {item.details.map((detail, index) => (
                  <Grid item sm={6} xs={12} key={index}>
                    <Grid container>
                      <Grid item xs={5} style={{ color: "#888" }}>
                        {detail.label}
                      </Grid>
                      <Grid item xs={7}>
                        {detail.value}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
        {/* end info section */}
      </Grid>
      {/* end mid section */}
      {/* last section */}
      <Grid container spacing={3} style={{ marginTop: "15px" }}>
        <Grid item sm={3} xs={12}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            {projectContent.contents.length !== 0 &&
              projectContent.contents.map((item, index) => (
                <Tab
                  label={item.label}
                  {...a11yProps(item.id)}
                  key={item.id}
                  style={
                    value === index
                      ? {
                          backgroundColor: "#e67e22",
                          color: "#fff",
                          fontSize: "inherit",
                          textAlign: "left",
                        }
                      : { backgroundColor: "#F8F8F8", fontSize: "inherit" }
                  }
                />
              ))}
          </Tabs>
        </Grid>
        <Grid item sm={9}>
          {projectContent.contents.length !== 0 &&
            projectContent.contents.map((item, index) => (
              <TabPanel
                value={value}
                index={index}
                key={item.id}
                style={{ backgroundColor: "#F8F8F8" }}
              >
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </TabPanel>
            ))}
        </Grid>
      </Grid>
      {/* end last section */}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default SingleProjectContent;
