import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const IntOrExtLink = ({ item }) => {
  if (item.slug === null && item.url === null) {
    return <div className="nav__menu-link">{item.title}</div>;
  } else if (item.slug) {
    return (
      <Link to={"/" + item.slug} className="footer__menu-link">
        {item.title}
      </Link>
    );
  } else if (item.url) {
    return (
      <a href={item.url} className="footer__menu-link">
        {item.title}
      </a>
    );
  }
};

const Footer = () => {
  const [footer, setFooter] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/getFooter").then((res) => {
      setFooter(res.data);
    });
  }, []);

  return (
    <>
      <div
        className="footer"
        style={{
          minHeight: "200px",
          margin: "20px 10px 10px 10px",
        }}
      >
        <Grid spacing={1}>
          <div dangerouslySetInnerHTML={{ __html: footer.footerContent }} />
        </Grid>
        <Grid container spacing={1}>
          <ul className="footer__menu">
            <li className="footer__menu-item">
              <Link to="/" className="footer__menu-link">
                Trang chủ
              </Link>
            </li>
            {footer.menu &&
              footer.menu.map((main) => {
                return (
                  <li className="footer__menu-item" key={main.id}>
                    <IntOrExtLink item={main} />
                  </li>
                );
              })}
          </ul>
        </Grid>
      </div>
      <div
        style={{ marginBottom: "10px", marginTop: "20px", textAlign: "center" }}
      >
        {/* © {new Date().getFullYear()} Copyright */}
        Copyright by ISSER, All rights reserved
      </div>
    </>
  );
};

export default Footer;
